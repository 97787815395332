import { createApp } from 'vue';

import App from './App.vue';

import io from 'socket.io-client';



// Conectem el socket.io al backend

const socket = io('http://136.144.205.127:5000'); // Adapta l'IP si és diferent



// Fem el socket accessible globalment

const app = createApp(App);



app.config.globalProperties.$socket = socket;



app.mount('#app');


