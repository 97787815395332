<template>

  <div>

    <h1>Monitoratge de Sensors</h1>



    <table>

      <tr>

        <th>Sensor ID</th>

        <th>Lectura</th>

        <th>Hora de Lectura</th>

      </tr>

      <tr v-for="lectura in sensorData" :key="lectura.reading_time">

        <td>{{ lectura.sensor_id }}</td>

        <td>{{ lectura.reading_value }}</td>

        <td>{{ new Date(lectura.reading_time).toLocaleString() }}</td>

      </tr>

    </table>

  </div>

</template>



<script>

import { io } from "socket.io-client";



export default {

  data() {

    return {

      sensorData: []

    };

  },



  mounted() {

    const socket = io("http://136.144.205.127:5000"); // Conectar al servidor WebSocket



    socket.on("sensor_data", (data) => {

      console.log("Dades rebudes via WebSocket:", data);

      this.sensorData.unshift({ 

        sensor_id: data.sensor_id, 

        reading_value: data.reading_value, 

        reading_time: new Date().toISOString() 

      });



      // Mantenir només els últims 10 registres (per exemple)

      if (this.sensorData.length > 10) {

        this.sensorData.pop();

      }

    });



    // Obtenir les lectures inicials

    fetch("http://136.144.205.127:5000/lecturas")

      .then(response => response.json())

      .then(data => {

        this.sensorData = data.map(lectura => ({

          sensor_id: lectura.sensor_id,

          reading_value: lectura.reading_value,

          reading_time: new Date(lectura.reading_time).toISOString()

        }));

      })

      .catch(error => console.error("Error al obtenir les lectures:", error));

  }

};

</script>



<style>

h1 {

  text-align: center;

}



table {

  margin: auto;

  border-collapse: collapse;

  width: 50%;

}



th, td {

  padding: 8px;

  text-align: center;

  border: 1px solid #ddd;

}



th {

  background-color: #f2f2f2;

}



tr:hover {

  background-color: #f5f5f5;

}

</style>


