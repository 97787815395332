<template>

  <div id="app">

    <!-- Mostrem el component SensorData, que serà on gestionem les dades -->

    <SensorData />

  </div>

</template>



<script>

import SensorData from './components/SensorData.vue';



export default {

  components: {

    SensorData

  }

};

</script>



<style>

#app {

  font-family: Avenir, Helvetica, Arial, sans-serif;

  -webkit-font-smoothing: antialiased;

  -moz-osx-font-smoothing: grayscale;

  text-align: center;

  color: #2c3e50;

  margin-top: 60px;

}

</style>


